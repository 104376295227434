export const Counter = () => {
    return (
        <section className="ftco-counter bg-primary" id="section-counter">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 mb-5 mb-md-0 text-center text-md-left">
                        <h2 className="font-weight-bold"
                            style={{ color: "#fff", fontSize: "22px", textTransform: "uppercase" }}>We're on
                            a mission to help all your problems</h2>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div
                                className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 text-center">
                                    <div className="text">
                                        <strong className="number" data-number="1005000">0</strong>
                                    </div>
                                    <div className="text">
                                        <span>Members</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 text-center">
                                    <div className="text">
                                        <strong className="number" data-number="65000">0</strong>
                                    </div>
                                    <div className="text">
                                        <span>Pastors</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 text-center">
                                    <div className="text">
                                        <strong className="number" data-number="500000">0</strong>
                                    </div>
                                    <div className="text">
                                        <span>Donations</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 text-center">
                                    <div className="text">
                                        <strong className="number" data-number="50">0</strong>
                                    </div>
                                    <div className="text">
                                        <span>Churches</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}