// import "./App.css"
import { HashRouter, Route, Routes as Switch } from "react-router-dom";
import { routes } from './constants/utils';
import { Home } from './screens/home';
import { Header } from './comonents/header';
import { Footer } from './comonents/footer';
import { Donate } from "./screens/don";
import { Orphelinat } from "./screens/orphelinat";
import { Hopital } from "./screens/hopital";
import { School } from "./screens/school";
import { Contacts } from "./screens/contacts";
import { Events } from "./screens/evenements";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Header />
        <Switch>
          <Route exact path={routes['fallback']} element={<Home />} />
          <Route exact path={routes['home']} element={<Home />} />
          <Route exact path={routes['donate']} element={<Donate />} />
          <Route exact path={routes['orphelinat']} element={<Orphelinat />} />
          <Route exact path={routes['hospital']} element={<Hopital />} />
          <Route exact path={routes['school']} element={<School />} />
          <Route exact path={routes['contact']} element={<Contacts />} />
          <Route exact path={routes['events']} element={<Events />} />
        </Switch>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
