import React, * as react from 'react'
import { app_longname, app_name } from '../constants/constants'
import { SHeader } from './sheader'
import { Link, useLocation } from 'react-router-dom'
import { routes } from '../constants/utils'

export const Header = () => {
    const loc = useLocation()
    const { pathname, hash, key, search, state } = loc

    React.useEffect(() => {

    }, []);

    return (
        <>
            <SHeader />
            <nav
                className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
                id="ftco-navbar">
                <div className="container">
                    <Link className="navbar-brand" to={routes['fallback']}>{app_name}</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="oi oi-menu"></span> Menu
                    </button>
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav ml-auto">
                            <li className={`nav-item ${pathname === routes['home'] || pathname === routes['fallback'] ? 'active' : ''}`}>
                                <Link to={routes['home']} className="nav-link">Acceuil</Link>
                            </li>
                            <li className={`nav-item ${pathname === routes['orphelinat'] ? 'active' : ''}`}>
                                <Link to={routes['orphelinat']} className="nav-link">Orphélinat</Link>
                            </li>
                            <li className={`nav-item ${pathname === routes['school'] ? 'active' : ''}`}>
                                <Link to={routes['school']} className="nav-link">Ecoles</Link>
                            </li>
                            <li className={`nav-item ${pathname === routes['hospital'] ? 'active' : ''}`}>
                                <Link to={routes['hospital']} className="nav-link">Hôpital</Link>
                            </li>
                            <li className={`nav-item ${pathname === routes['events'] ? 'active' : ''}`}>
                                <Link to={routes['events']} className="nav-link">Evenements</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={routes['blog']} className="nav-link">Blog</Link>
                            </li> */}
                            <li className={`nav-item ${pathname === routes['about'] ? 'active' : ''}`}>
                                <Link to={routes['about']} className="nav-link">A propos de nous</Link>
                            </li>
                            <li className={`nav-item ${pathname === routes['contact'] ? 'active' : ''}`}>
                                <Link to={routes['contact']} className="nav-link">Contacts</Link>
                            </li>
                            <li className={`nav-item cta ${pathname === routes['donate'] ? 'active' : ''}`}>
                                <Link to={routes['donate']} className="nav-link" >Faites un don</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}