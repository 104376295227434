import * as React from 'react'
import { app_adresse } from '../constants/constants'
import { message } from 'antd'

export const SHeader = () => {
    return (
        <div className="wrap">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                        <p className="mb-0 location">
                            <span className="fa fa-map-marker mr-2"></span> {app_adresse}
                        </p>
                    </div>
                    <div className="col-md-6 d-flex justify-content-md-end">
                        <div className="social-media">
                            <p className="mb-0 d-flex">
                                <a href="#"
                                    target='_blank'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        message.info("Non pris en charge pour le moment !")
                                    }}
                                    className="d-flex align-items-center justify-content-center"><span
                                        className="fa fa-facebook"><i className="sr-only">Facebook</i></span></a>
                                <a href="#"
                                    target='_blank'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        message.info("Non pris en charge pour le moment !")
                                    }}
                                    className="d-flex align-items-center justify-content-center"><span
                                        className="fa fa-twitter"><i className="sr-only">Twitter</i></span></a>
                                <a href="#"
                                    target='_blank'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        message.info("Non pris en charge pour le moment !")
                                    }}
                                    className="d-flex align-items-center justify-content-center"><span
                                        className="fa fa-instagram"><i className="sr-only">Instagram</i></span></a>
                                <a href="https://youtube.com/@apdm-eglise?si=_rDaYSb_0ob3z3Qj"
                                    target='_blank'
                                    className="d-flex align-items-center justify-content-center"><span
                                        className="fa fa-youtube"><i className="sr-only">Youtube</i></span></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}