import * as React from 'react'
import { Hero } from '../comonents/hero'
import { Counter } from '../comonents/counter'
import { SermonsScreen } from '../comonents/sermons'
import { TestimonyScreen } from '../comonents/testmony'
import { EventsScreen } from '../comonents/events'
import { GalleryScreen } from '../comonents/gallery'

export const Home = () => {

    React.useEffect(() => {
    }, [])

    return (
        <>
            <Hero />
            <Counter />
            <SermonsScreen />
            {/* <TestimonyScreen /> */}
            <EventsScreen />
            <GalleryScreen />
        </>
    )
}