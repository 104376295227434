import * as React from 'react'
import bg_1 from '../assets/images/bg_1.jpg'
import bg_2 from '../assets/images/bg_2.jpg'
import about from '../assets/images/about-3.jpg'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { app_longname, app_name } from '../constants/constants';
import { routes } from '../constants/utils';
import { Link } from 'react-router-dom';



export const Hero = () => {

    return (
        <>
            <section className="hero-wrap js-fullheight">
                <OwlCarousel
                    className='owl-theme owl-carousel home-slider js-fullheight'
                    loop
                    margin={10}
                    // nav
                    autoplay
                    items={1}
                    dots={null}
                    animateIn
                    animateOut
                >
                    <div className="slider-item js-fullheight"
                        style={{ backgroundImage: `url(${bg_1})` }}>
                        <div className="overlay"></div>
                        <div className="container">
                            <div
                                className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                                <div className="col-md-8 ftco-animate">
                                    <div className="text mt-md-5 w-100 text-center">
                                        <h2>{app_name}</h2>
                                        {/* A.P.D.M */}
                                        <h1 className="mb-3">{app_longname}</h1>
                                        {/* Assemblée Pentecôtiste de Dieu en Mission */}
                                        <p className="mb-4 pb-3">{app_name} est un lieu de foi et de communion où les fidèles se réunissent pour célébrer, prier et grandir ensemble spirituellement. Ses services enrichissants et son atmosphère chaleureuse en font un pilier de la communauté chrétienne.</p>
                                        <p className="mb-0">
                                            <Link to={routes['donate']} className="btn btn-primary py-3 px-2 px-md-4">Faites un don</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-item js-fullheight"
                        style={{ backgroundImage: `url(${bg_2})` }}>
                        <div className="overlay"></div>
                        <div className="container">
                            <div
                                className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                                <div className="col-md-8 ftco-animate-">
                                    <div className="text mt-md-5 w-100 text-center">
                                        <h2>Bienvenu sur le portail de {app_name}</h2>
                                        <h1 className="mb-3">Des services au profit de la communauté; pour l'oeuvre de Dieu</h1>
                                        <p className="mb-4 pb-3">L'église {app_name} s'engage activement dans la communauté en soutenant un orphelinat, une école et un hôpital, offrant ainsi des ressources essentielles et des soins à ceux qui en ont le plus besoin. À travers ces initiatives, APDM démontre son dévouement à apporter espoir et réconfort aux plus vulnérables.</p>
                                        <p className="mb-0">
                                            <Link to={routes['donate']} className="btn btn-primary py-3 px-2 px-md-4">Devenez un volontaire</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </section>
            <section className="ftco-section ftco-no-pb ftco-no-pt">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-8 d-flex">
                            <div className="row no-gutters">
                                <div className="col-md-4">
                                    <div className="services-2">
                                        <div className="icon"><span className="flaticon-church"></span></div>
                                        <div className="text">
                                            <h4>Worhip</h4>
                                            <span className="subheading">What to expect</span>
                                            <p>Far far away, behind the word mountains, far from the countries
                                                Vokalia and Consonantia, there live the blind texts.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="services-2">
                                        <div className="icon"><span className="flaticon-pray"></span></div>
                                        <div className="text">
                                            <h4>Connect</h4>
                                            <span className="subheading">Contact Members</span>
                                            <p>Far far away, behind the word mountains, far from the countries
                                                Vokalia and Consonantia, there live the blind texts.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="services-2">
                                        <div className="icon"><span className="flaticon-love"></span></div>
                                        <div className="text">
                                            <h4>God's Love</h4>
                                            <span className="subheading">Beliefs and History</span>
                                            <p>Far far away, behind the word mountains, far from the countries
                                                Vokalia and Consonantia, there live the blind texts.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex">
                            <div className="services-2 services-block">
                                <div className="text">
                                    <h4>A Christian should live for the glory of God and the well-being of
                                        others.</h4>
                                    <p>Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section ftco-no-pt ftco-no-pb bg-light">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-6 d-flex">
                            <div
                                className="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0"
                                style={{ backgroundImage: `url(${about})` }}>
                            </div>
                        </div>
                        <div className="col-md-6 pl-md-5 py-md-5">
                            <div className="heading-section pt-md-5 mb-4">
                                <span className="subheading">Welcome to Salvation Church</span>
                                <h2 className="mb-5">Connect, Grow and Serve with Us</h2>
                                <p>A small river named Duden flows by their place and supplies it with
                                    the necessary regelialia. It is a paradisematic country, in which
                                    roasted parts of sentences fly into your mouth.</p>
                                <p>A small river named Duden flows by their place and supplies it with
                                    the necessary regelialia. It is a paradisematic country, in which
                                    roasted parts of sentences fly into your mouth.</p>
                                <p><a href="#" className="btn btn-primary">Learn More</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}