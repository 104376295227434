import * as React from 'react'
import { app_adresse, app_email, app_phone, app_website } from '../constants/constants'
import bg_1 from '../assets/images/bg_1.jpg'
import { Link } from 'react-router-dom'
import { routes } from '../constants/utils'

export const Contacts = () => {
    return (
        <>
            <section className="hero-wrap hero-wrap-2 js-fullheight"
                style={{ backgroundImage: `url(${bg_1})` }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end js-fullheight">
                        <div className="col-md-9 ftco-animate pb-5">
                            <p className="breadcrumbs mb-2"><span className="mr-2"><Link to={routes['home']}>Acceuil &nbsp;&nbsp;
                                <i className="fa fa-chevron-right mx-2"></i></Link></span> <span>Contactes  &nbsp;&nbsp;<i
                                    className="fa fa-chevron-right mx-2"></i></span></p>
                            <h1 className="mb-0 bread">Nos contactes</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="row mb-5">
                                    <div className="col-md-3">
                                        <div className="dbox w-100 text-center">
                                            <div
                                                className="icon bg-primary d-flex align-items-center justify-content-center">
                                                <span className="fa fa-map-marker"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Addresse:</span>{app_adresse}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="dbox w-100 text-center">
                                            <div
                                                className="icon bg-secondary d-flex align-items-center justify-content-center">
                                                <span className="fa fa-phone"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Phone:</span> <a href={`tel://${app_phone}`} >{app_phone}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="dbox w-100 text-center">
                                            <div
                                                className="icon bg-tertiary d-flex align-items-center justify-content-center">
                                                <span className="fa fa-paper-plane"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Email:</span> <a
                                                    href="#"><span
                                                        className="__cf_email__"
                                                        data-cfemail="d3babdb5bc93aabca6a1a0baa7b6fdb0bcbe">{app_email}</span></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="dbox w-100 text-center">
                                            <div
                                                className="icon bg-quarternary d-flex align-items-center justify-content-center">
                                                <span className="fa fa-globe"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Website</span> <a target='_blank' href={app_website}>{app_website}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-7">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <h3 className="mb-4">Nous contacter</h3>
                                            <form method="POST" id="contactForm" name="contactForm"
                                                className="contactForm">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" for="name">Nom complet</label>
                                                            <input type="text" className="form-control" name="name" id="name"
                                                                placeholder="Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" for="email">Email Address</label>
                                                            <input type="email" className="form-control" name="email" id="email"
                                                                placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="label" for="subject">Subject</label>
                                                            <input type="text" className="form-control" name="subject"
                                                                id="subject" placeholder="Subject" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="label" for="#">Message</label>
                                                            <textarea name="message" className="form-control" id="message"
                                                                cols="30" rows="4" placeholder="Message"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input type="submit" value="Send Message" className="btn btn-primary" />
                                                            <div className="submitting"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-5 d-flex align-items-stretch">
                                        <div className="info-wrap w-100 p-5 img"
                                            style={{ backgroundImage: `url(${bg_1})` }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div id="map" className="map"></div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}