export const routes = {
    fallback: "/",
    home: "/app/home",
    about: "/app/about",
    ministries: "/app/ministries",
    sermons: "/app/sermons",
    events: "/app/events",
    blog: "/app/blog",
    contact: "/app/contacts",
    donate: "/app/donate",
    orphelinat: "/app/orphelinat",
    school: "/app/school",
    hospital: "/app/hospital"
}