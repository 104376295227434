import * as React from 'react'
import image_1 from '../assets/images/image_1.jpg'
import image_2 from '../assets/images/image_2.jpg'
import { app_adresse, app_email, app_name, app_phone } from '../constants/constants'

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <h2 className="footer-heading">{app_name}</h2>
                        <p>A small river named Duden flows by their place and supplies it with the
                            necessary regelialia.</p>
                        <ul className="ftco-footer-social p-0">
                            <li className="ftco-animate"><a href="#" data-toggle="tooltip"
                                data-placement="top" title="Twitter"><span
                                    className="fa fa-twitter"></span></a></li>
                            <li className="ftco-animate"><a href="#" data-toggle="tooltip"
                                data-placement="top" title="Facebook"><span
                                    className="fa fa-facebook"></span></a></li>
                            <li className="ftco-animate"><a href="#" data-toggle="tooltip"
                                data-placement="top" title="Instagram"><span
                                    className="fa fa-instagram"></span></a></li>
                            <li className="ftco-animate"><a href="https://youtube.com/@apdm-eglise?si=_rDaYSb_0ob3z3Qj" data-toggle="tooltip"
                                data-placement="top" title="Youtube" target="_blank"><span
                                    className="fa fa-youtube"></span></a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <h2 className="footer-heading">Dernières informations</h2>
                        <div className="block-21 mb-4 d-flex">
                            <a className="img mr-4 rounded"
                                style={{ backgroundImage: `url(${image_1})` }}></a>
                            <div className="text">
                                <h3 className="heading"><a target='_blank' href="https://www.youtube.com/post/UgkxNyQU7pPNvzU-IH4HhmJtEVAqIkSi-1gv">Tournage de notre album : 1</a></h3>
                                <div className="meta">
                                    <div><a href="#">Juillet. 2024</a></div>
                                    <div><a href="#">Admin</a></div>
                                    <div><a href="#">19</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="block-21 mb-4 d-flex">
                            <a className="img mr-4 rounded"
                                style={{ backgroundImage: `url(${image_2})` }}></a>
                            <div className="text">
                                <h3 className="heading"><a href="#">Tournage de notre album : 2</a></h3>
                                <div className="meta">
                                    <div><a href="#">Juillet. 2024</a></div>
                                    <div><a href="#">Admin</a></div>
                                    <div><a href="#">19</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 pl-lg-5 mb-4 mb-md-0">
                        <h2 className="footer-heading">Liens directs</h2>
                        <ul className="list-unstyled">
                            <li><a href="#" className="py-2 d-block">Acceuil</a></li>
                            <li><a href="#" className="py-2 d-block">A propos de nous</a></li>
                            <li><a href="#" className="py-2 d-block">Sermons</a></li>
                            <li><a href="#" className="py-2 d-block">Evenements</a></li>
                            <li><a href="#" className="py-2 d-block">Blog</a></li>
                            <li><a href="#" className="py-2 d-block">Contacts</a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <h2 className="footer-heading">Vous avez une question ?</h2>
                        <div className="block-23 mb-3">
                            <ul>
                                <li><span className="icon fa fa-map"></span><span className="text">{app_adresse}</span></li>
                                <li><a href="#"><span className="icon fa fa-phone"></span><span
                                    className="text">{app_phone}</span></a></li>
                                <li><a href="#"><span className="icon fa fa-paper-plane"></span><span
                                    className="text"><span className="__cf_email__"
                                        data-cfemail="6c05020a032c1503191e0803010d0502420f0301">{app_email}</span></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-12 text-center">
                        <p className="copyright">
                            Copyright &copy;{new Date().getFullYear()}
                            All rights reserved | This web site was made with <i className="fa fa-heart"
                                aria-hidden="true"></i> by <a href="https://buhendje.com/"
                                    target="_blank">Buhendje Soft Services</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer >
    )
}