import * as React from 'react'
import { app_longname, app_name } from '../constants/constants'
import { Link } from 'react-router-dom'
import { routes } from '../constants/utils'
import bg_1 from '../assets/images/bg_1.jpg'

export const Building = () => {
    return (
        <>
            <div className="slider-item js-fullheight"
                style={{ backgroundImage: `url(${bg_1})` }}>
                <div className="overlay"></div>
                <div className="container">
                    <div
                        className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                        <div className="col-md-8 ftco-animate">
                            <div className="text mt-md-5 w-100 text-center">
                                <h2>Page encours de construction</h2>
                                <h1 className="mb-3">{app_longname}</h1>
                                {/* <p className="mb-4 pb-3">cette page est encours de construction</p> */}
                                <p className="mb-0">
                                    <Link to={routes['home']} className="btn btn-primary py-3 px-2 px-md-4">Retour sur la page principale</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}