import * as React from 'react'
import image from '../assets/images/gallery-1.jpg';
export const GalleryScreen = () => {
    const [galleries, setgalleries] = React.useState([])
    const [length,] = React.useState(44)

    const ajouterElement = () => {
        setgalleries(prevTableau => {
            for (let index = 0; index < length; index++) {
                console.log('====================================');
                console.log(index, length);
                console.log('====================================');
                return [...prevTableau, prevTableau.length + 1];
            }
        });
    };

    React.useEffect(() => {
        ajouterElement()
        // for (let index = 0; index < length; index++) {
        //     console.log("index ==> ", index, index, index);
        //     galleries.push({
        //         key: 1,
        //         image: `../assets/images/gallery-${index + 1}.jpg`
        //     })
        //     setgalleries(galleries)
        // }
    }, []);

    return (
        <section className="ftco-section ftco-no-pb ftco-no-pt">
            <div className="container-fluid px-md-0">
                <div className="row no-gutters justify-content-center pb-5 mb-3">
                    <div className="col-md-7 heading-section text-center ftco-animate">
                        <span className="subheading">Gallerie</span>
                        <h2>Notre gallerie lors du tournage de notre premier album de la chorale ( UMOJA WETU KWAYA )</h2>
                    </div>
                </div>
                <div className="row no-gutters">
                    {galleries.reverse().slice(0, 7).map((v, k) => {
                        const { key, image: asimage } = v;
                        return (
                            <div className="col-md-3">
                                <a href={`${image}`}
                                    className="image-popup img gallery ftco-animate"
                                    style={{ backgroundImage: `url(${image})` }}>
                                    <span className="overlay"></span>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}